<template>
  <v-layout>
    <div v-if="noData" class="presc--fullscreen">
      <v-progress-circular :size="50" indeterminate color="primary" />
    </div>
  </v-layout>
</template>

<script>
import { pdfPrescriptionUrl } from '../config/config';
import DownloadFile from '@/mixins/DownloadFile';

export default {
  name: 'PdfPrescription',
  mixins: [DownloadFile],
  data() {
    return {
      noData: true,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  async created() {
    await this.getData();
    this.noData = false;
  },

  methods: {
    async getData() {
      const patientId = this.$route?.params?.patient;
      const prescriptionId = this.$route?.params?.id;
      if (parseInt(patientId) !== this.authUser.patient.id || !prescriptionId) return;

      await this.download({
        url: `${pdfPrescriptionUrl}/${patientId}/prescriptions/${prescriptionId}`,
        name: this.$t('prescriptions.prescription') + prescriptionId + '.pdf',
        type: 'application/pdf',
        open: true,
        openInNewTab: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.presc--fullscreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
